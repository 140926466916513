<template>
    <b-card bg-variant="Default">
      
      <!-- table -->
      <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      :isLoading.sync="isLoading"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
          enabled: true,
          mode: 'pages',
          nextLabel: 'successiva',
          prevLabel: 'precedente',
          rowsPerPageLabel: 'righe per pagina',
          ofLabel: 'di',
          pageLabel: 'pagina', // for 'pages' mode
          perPage: 10
      }"
      styleClass="vgt-table">
  
        <template slot="loadingContent_stop">
          <div class="bg-primary"> animated loading ....</div>
        </template>
  
        <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>
  
        <template
          slot="table-row"
          slot-scope="props"
        >
  
          <!-- Column: Codice -->
          <div
            v-if="props.column.field === 'codice'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.codice }}</span>
          </div>
  
          <!-- Column: File -->
          <span v-else-if="props.column.field === 'file'">
            <div class="text-nowrap">
              <a 
                target="_blank"
                :href="props.row.file"
                class="text-reset"
                v-if="props.row.file != ''"
                >
                <feather-icon
                    :id="`tabellaarticoli-riga-${props.row.id}-download`"
                    icon="DownloadIcon"
                    class="cursor-pointer mr-1"
                    size="21"
                />
                <b-tooltip
                    title="Download"
                    class="cursor-pointer"
                    :target="`tabellaarticoli-riga-${props.row.id}-download`"
                />
              </a>
            </div>
          </span>
  
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <div class="text-nowrap">
              <feather-icon
                  :id="`tabellaarticoli-riga-${props.row.id}-nuova-richiesta`"
                  icon="FileTextIcon"
                  class="cursor-pointer mr-1"
                  size="21"
                  @click="nuovaRichiesta()"
              />
              <b-tooltip
                  title="Richiedi preventivo"
                  class="cursor-pointer"
                  :target="`tabellaarticoli-riga-${props.row.id}-nuova-richiesta`"
              />
  
              <feather-icon
                  :id="`tabellaarticoli-riga-${props.row.id}-preview`"
                  icon="InfoIcon"
                  class="cursor-pointer mr-1"
                  size="21"
                  @click="apriPreview(props.row.id)"
              />
              <b-tooltip
                  title="preview"
                  class="cursor-pointer"
                  :target="`tabellaarticoli-riga-${props.row.id}-preview`"
              />
            </div>
          </span>
  
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
  
        
      </vue-good-table>
  
      <b-modal ref="my-modal" hide-footer centered title="Informazioni Articolo" modal-class="modal-primary" size="lg">
        <div class="d-block text-center">
          <b-row class="pb-2 text-left">
              <b-col md="4" lg="3">Codice</b-col>
              <b-col md="8" lg="9">{{ infomodal.codice }}</b-col>
          </b-row>
          <b-row class="pb-2 text-left">
              <b-col md="4" lg="3">Articolo</b-col>
              <b-col md="8" lg="9">{{ infomodal.descrizione }}</b-col>
          </b-row>
          <b-row class="pb-2 text-left">
              <b-col md="4" lg="3">Unità </b-col>
              <b-col md="8" lg="9">{{ infomodal.um }}</b-col>
          </b-row>
          <b-row class="pb-2 text-left">
              <b-col md="4" lg="3">Ultimo aggiornamento</b-col>
              <b-col md="8" lg="9">{{ infomodal.dataupd }}</b-col>
          </b-row>
        </div>
      </b-modal>
  
    </b-card>
  
  </template>
  
  <script>
  import { BRow, BCol } from 'bootstrap-vue'
  import { BCard, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip, BModal, VBModal } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  
  export default {
    components: {
      BRow,
      BCol,
  
      BCard,
      VueGoodTable,
      BButton,
      BTooltip,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BModal,
    },
    directives: {
      'b-modal': VBModal
    },
    data() {
      return {
        isLoading: true,
        emptystate: true,
        pageLength: 10,
        columns: [
          {
            label: 'Codice',
            field: 'codice',
            filterOptions: {
              enabled: true,
              placeholder: 'Ricerca per Codice',
            },
          },
          {
            label: 'Articolo',
            field: 'descrizione',
            filterOptions: {
              enabled: true,
              placeholder: 'Ricerca per Nome Articolo',
            },
          },
          {
            label: 'File',
            field: 'file',
          },
          {
            label: 'Operazioni',
            field: 'action',
          },
        ],
        rows: [],
        searchTerm: '',
        totalRecords: 0,
        serverParams: {
          columnFilters: {
          },
          sort: [
              {
              field: '',
              type: ''
              }
          ],
          page: 1, 
          perPage: 10,
          licenza_pubblica: 'testlicenza'
        },
        infomodal: {
          emailValue: '',
          nomeecognome: '',
          startdata: '',
          salario: '',
          selected: '',
        },
        titolo_model_aggiungi: '',
        name: 0,
        nameState: null,
        id_articolo_passato: 0,
        id_azienda_passata: 0,
        userData: JSON.parse(localStorage.getItem('userData')),
      }
    },
    computed: {
      statusVariant() {
        const statusColor = {
          '0.00'       : 'light-danger'
        }
  
        return status => statusColor[status]
      },
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true
          return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
        return this.dir
      },
    },
    created() {
      console.log('created ....................')
  
      //this.rows = [];
      this.loadItems();
      
    },
    methods: {
      updateParams(newProps) {
        //console.log("updateParams --- vvv ")
        console.log(newProps)
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
  
      onPerPageChange(params) {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
      },
  
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          }],
        });
        this.loadItems();
      },
      
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
  
      // load items is what brings back the rows from server
      loadItems() {
          
          //console.log("loadItems ......");
          //console.dir(this.serverParams)
          //this.isLoading = true;
  
          this.$http.get('v2/articoli/lista_articoli_generale', {
              params: this.serverParams 
              }).then(response => { 
                  this.totalRecords = response.data[0].contarighe;
                  this.rows = response.data[1].righe;
                  
                  //rimuovi il loading dopo il caricamento dei dati nella tabella
                  this.isLoading = false;
              
              })  
    
      },
  
      apriPreview(id){
        this.$http.get('v2/articoli/singolo_articolo/'+id)
          .then(response => { 
              //console.log("risposta server --------")
              //console.log(response.data)
  
              if(response.data.statusCode===200){
              //risposta positiva
                  this.infomodal = { codice: response.data.dati.codice,
                  descrizione: response.data.dati.descrizione,
                  um: response.data.dati.um,
                  giacenza: response.data.dati.giacenza,
                  dataupd: response.data.dati.dataupd,
                  scadenza_offerta: response.data.dati.scadenza_offerta, };
  
                  this.$refs['my-modal'].show()
              } else {
              //risposta negativa (errore sul server)
              
              }
          }).catch(e => {
              console.log(e);
          });
      },
      nuovaRichiesta(){
          this.$router.replace('/azienda/lead/nuovo')
      },
    }
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  